import {QueryHookOptions, gql, useLazyQuery} from "@apollo/client";
import {API_CLIENT} from "@services/graphql/client";
import {useMemo} from "react";

import {useAuthedMonolithQuery} from "../../../../hooks/useAuthedMonolithQuery";
import {useDefaultPatientQuery} from "./useDefaultPatientQuery";

type RawPatientFragment = {
  id: string;
  firstName: string;
  lastName: string;
  birthdate: string;
  relationship: string;
};

type PatientFragment = {
  id: string;
  firstName: string;
  lastName: string;
  age: number;
  relationship: string;
};

export type FamilyMembersQuery = {
  familyMembers: RawPatientFragment[];
};

export type FamilyMembersQueryVariables = {
  consumerId: string;
};

/**
 * @param birthdate YYYY-MM-DD formated date string i.e. 2021-10-12
 * @returns age as an integer
 */
const transformBirthdateToAge = (birthdate: string) => {
  const birthdateDate = new Date(birthdate);
  const today = new Date();
  const age = today.getFullYear() - birthdateDate.getFullYear();
  const m = today.getMonth() - birthdateDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthdateDate.getDate())) {
    return age - 1;
  }
  return age;
};

export const GET_FAMILY_MEMBERS = gql`
  query GetFamilyMembers($consumerId: String) {
    familyMembers(consumerId: $consumerId)
      @rest(
        type: "PatientFragment"
        endpoint: "monolith"
        path: "/hib/consumers/{args.consumerId}/patients"
      ) {
      id
      firstName
      lastName
      birthdate
      relationship
    }
  }
`;

const useFamilyMembersQuery = (
  options: Omit<QueryHookOptions<FamilyMembersQuery, FamilyMembersQueryVariables>, "context"> = {},
) =>
  useAuthedMonolithQuery<FamilyMembersQuery, FamilyMembersQueryVariables>(
    GET_FAMILY_MEMBERS,
    options,
  );

export const useFamilyMembersForAuthedPatientQuery = ({skip = false}: {skip?: boolean} = {}) => {
  const patientQuery = useDefaultPatientQuery();
  const consumerId = patientQuery.data?.patient?.consumerId;

  const familyMembersQuery = useFamilyMembersQuery({
    variables: {
      consumerId: consumerId || "",
    },
    skip: !consumerId || skip,
  });

  return {
    familyMembers: useMemo<PatientFragment[]>(
      () =>
        familyMembersQuery.data?.familyMembers?.map(({birthdate, ...fm}) => ({
          ...fm,
          age: transformBirthdateToAge(birthdate),
        })) || [],
      [familyMembersQuery.data?.familyMembers],
    ),
    loading: patientQuery.loading || familyMembersQuery.loading,
  };
};

export const useFamilyMembersForAuthedPatientLazyQuery = () =>
  useLazyQuery<FamilyMembersQuery, FamilyMembersQueryVariables>(GET_FAMILY_MEMBERS, {
    context: {
      clientName: API_CLIENT,
    },
  });
